.bloglist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 60px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* h1 {
    margin-bottom: 20px;
  } */
  
  .table-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: auto;
  }
  
  /* table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
  }
  
  th {
    background-color: white;
  }
  
  td {
    border: none;
    background-color: transparent;
  }
  
  tr:nth-child(even) {
    background-color: transparent;
  } */
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .btn {
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
    color: white;
  }
  
  .update-btn {
    background-color: #2196f3; /* Blue */
  }
  
  .view-btn {
    background-color: #2196f3; /* Blue */
  }
  
  .delete-btn {
    background-color: #f44336; /* Red */
  }
  
  .divider {
    width: 100%;
    border: none;
    border-top: 2px solid #ddd;
    margin: 20px 0;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    
    margin: 20px auto 0; /* Margin at the bottom of the container */
  }
  
  .rows-per-page, .page-number {
    margin-right: 20px;
  }
  
  /* label {
    margin-right: 8px;
  } */
  
  /* select {
    padding: 4px 8px;
    font-size: 14px;
  } */
  
  /* Pagination text and buttons outside container, top-right corner */
  .pagination-top-right {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .pagination-text {
    font-size: 14px;
  }
  
  .pagination-button {
    border: none;
    background-color: #2196f3; /* Blue */
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  