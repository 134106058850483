.DutySaverSrtipes {
    padding: 10px;
    background-color: #1994b7;
    border-radius: 7px;
    color: #fff;
}

.hide_row {
    display: none;
}

.hide_section {
    display: none;
}

/* --------------------------------------------success -msg-------------------------------------- */
.congrat-success-msg {
    color: #1994b7;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
}
.saver-pro-landed-cost{
    padding: 10px;
    border: 1px solid #dadada;
    border-radius: 7px;
    margin-top: 10px;
}

.landed-cost-main{
    font-size: 20px !important;
    font-weight: 700 !important;
    /* color: #202224 !important; */
  
  }
  .landed-cost-main-2{
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: auto !important;
    /* color: #202224 !important; */
  }
  .landed-cost-sub{
    font-size: 14px !important;
    font-weight: 700 !important;
    color:"#797D8C" !important;
  }
  .landed-cost-body-1,.landed-cost-body-2{
    font-size: 12px !important;
    color:#797D8C !important;
  
  }
  .landed-cost-body-1{
    font-weight: 500 !important;
  }
  .landed-cost-sub-1{
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #0f4078;
  }

  .landed-cost-sub-2{
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #0f5132;
  }
  .landed-cost-body-2{
    font-weight: 500 !important;
    margin-top: 10px !important;
  }
/* -----------------------------------------------------origin rule------------------------------ */
.origin-rules {
    border: 1px solid #888;
    border-radius: 8px;
}

.landed-cost-and-origin-rule {
    margin: 15px 0;
}

.tnc-note-exp,.tnc-note-imp,.fta-landed-cost{
    font-weight: 800;
}
.tnc-note-3{
    margin-top: 20px;
    background-color: #ffff;
    padding: 10px;
    border-radius: 7px;
    font-weight: 700;
    color: #797d8c;
}
.rules-note{
    padding: 10px;
}
.rules-note-desc{
    font-weight: 700;
    font-size: 10px;
}
.read-more-btn{
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.saver-notes{
  padding: 10px;
  margin: 0;
}
/* ----------------------------------------------------summary ------------------------------------ */
.fta-summary-under {
    padding: 10px 0px !important;
    background-color: #0088af !important;
    border-radius: 7px 7px 0 0 !important;
    color: #fff !important;
    margin: 0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 1.6 !important;
    text-align: center !important;
    margin-bottom: 0.35em !important;
}

.fta-summary-data-value{
    padding: 5px !important;
    display: flex !important;
    flex-direction: column !important;
}
.fta-summary-under-header{
    padding: 5px !important;
    font-weight: 700 !important;
}

.footnotes {
    padding: 10px;
    background-color: #1994b7;
    border-radius: 7px 7px 0 0;
    color: #fff;
  }