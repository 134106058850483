.contactUsContainer {
    >div:nth-child(1) {
        position: fixed;
        z-index: 1;
        margin-block-start: -6%;
    }

    >div:nth-child(2) {
        // padding-block-start: 10%;
        margin-block-start: 6%;
    }
    >div:nth-child(2) {
        // background-color: #ffffff;
        // color: #444444;
        // z-index: 2 !important;
    }

    .contactUs {
        padding-inline: 10%;
        padding-block: 5%;
        display: flex;
        justify-content: space-between;
        // background-color: #FAFAFA;

        >div:nth-child(1) {
            position: relative;
            // margin-block: auto;

            >div:nth-last-child(1) {
                margin-block-start: -34%;
                margin-inline-start: 80%;
            }

            >div:nth-last-child(2) {
                margin-block-start: -52.5%;
                margin-inline-start: -60%;
            }
        }

        >div:nth-child(2) {
            background-image: url('../../assest/Untitled\ design-26\ 1.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            >div {
                padding-block: 3rem;
                padding-inline: 3rem;
            }
        }
    }



}