.CommonHeading{
    display: flex;
    justify-content: space-between;
    padding-inline-end: 2%;
    >div:nth-child(2){
        height: fit-content;
        margin-block: auto;
        padding-inline-end:3%;
        svg{
            font-size: 1.3rem;
        }
        svg:nth-child(1){
            margin-inline-end: 0.5rem;
        }
    }
}