.videoModalContainer {
    width: 60%;
    height: fit-content;
    margin: auto;

    @media screen and (max-width:480px) {
        width: 100%;

    }

    .videoModal {
        border: 0.5rem;
        position: relative;

        video {
            width: 100%;
            height: 100%;
        }

        svg {
            position: absolute;
            bottom: 100%;
            left: 100%;
        }
    }
}