#importExportSelect {
  width: 100%;
  padding: 16.5px 5px;
  border-radius: 5px;
  border-color: #88888863;
  color: #888;
}

.duty-calculator-page-main-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 10px;
}

.switch-option{
  display: flex;
  flex-direction: column;
}

.duty-calculator-section1 {
  width: 30%;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.duty-calc-label {
  margin-bottom: 8px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.selectContainer {
  width: 100%;
  margin-bottom: 16px;
}


.duty-calculator-section2 {
  width: 70%;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.duty-calculator-main-SearchDiv {
  display: flex;
  gap: 2px;
  width: 100%;
  margin-bottom: 4px;
}

.unable-to-locate-hs-code{
  margin: 0px;
    font-size: 0.75rem;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}



/* .button {
    padding: 1px;
  } */

.duty-calculator-section3 {
  border-radius: 8px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* 'full' replaced with 100% */
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}


.hide_row {
  display: none !important;
}

.BreakDownDutyTax,
.shipment-summary,
.footnotes {
  padding: 10px;
  background-color: #1994b7;
  border-radius: 7px 7px 0 0;
  color: #fff;
}
.BreakDownDutyTax,.shipment-summary{
  font-size: 18px !important;
  font-weight: 600 !important;
}
.landed-cost-main{
  font-size: 20px !important;
  font-weight: 700 !important;
  /* color: #202224 !important; */

}
.landed-cost-main-2{
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: auto !important;
  /* color: #202224 !important; */
}
.landed-cost-sub,.dark-landed-cost-sub{
  font-size: 12px !important;
  font-weight: 700 !important;
}
.landed-cost-sub{
  color:"#797D8C" !important;
}
.dark-landed-cost-sub{
  color:"#fff" !important;
}
.landed-cost-body-1,.landed-cost-body-2{
  font-size: 12px !important;
  color:#797D8C !important;

}
.landed-cost-body-1{
  font-weight: 500 !important;
}
.landed-cost-sub{
  font-weight: 700 !important;
}
.landed-cost-body-2{
  font-weight: 500 !important;
  margin-top: 10px !important;
}

.footnotes-list{
  color:#797D8C !important;
}
.dark-footnotes-list{
  color:#fff !important;
}

.footnotes {
  counter-reset: footnote-counter !important;
  /* padding-left: 0 !important;  */
}

.footnotes-list {
  counter-increment: footnote-counter !important;
  position: relative !important; 
  padding-left: 30px !important; 
  margin-bottom: 10px !important; 
  list-style: none !important; 

}

.footnotes-list::before {
  content: counter(footnote-counter) !important;
  color: #333333;
  border-radius: 6px !important;
  padding: 5px 10px !important;
  position: absolute !important;
  left: -30px !important;
  top: 12px !important;
  transform: translateY(-50%) !important;
  background-color: #ffb966 !important;
  color: #fff !important;
  font-weight: 600;
}

.duty-saver-pro-btn {
  padding: 10px;
  background-color: #1994b7;
  color: #fff;
  border-radius: 7px;
}

.saver-strip-table-heading {
  background-color: #1994b7;
  color: #fff;
  text-align: center;
  font-size: 2rem;
}

/* ---------------------------------------model------------------------------------------------ */
.point-deduction-msg,
.point-want-to-proceed {
  font-weight: 700;
  text-wrap: nowrap;
  text-align: center;
}

.highlight-msg {
  font-size: 1.5rem;
  font-weight: 700;
}

.point-deduction-yes-btn {
  background-color: var(--intgrow-bg) !important;
  color: #fff !important;
  font-weight: 800 !important;
}

.point-deduction-cancel-btn {
  border: 1px solid red !important;
  width: 60% !important;
  margin: 10px auto !important;
  color: red !important;
  font-weight: 800 !important;

}

.save-huge-msg-section {
  border: 1px solid red;
  border-radius: 7px;
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.unlock-huge-saving,
.save-upto,
.proceed-now {
  color: #000;

}

.unlock-huge-saving {
  font-size: 1.2rem;
  font-weight: 800;
  margin: 5px;
}

.proceed-now {
  padding: 5px 10px;
  background-color: pink;
  border-radius: 7px;
}

.duty-saver-points-deduction-notes {
  border: 1px solid var(--intgrow-bg);
  border-radius: 7px;
}
.notes{
  background-color: var(--intgrow-bg);
    color: #fff;
    padding: 5px 10px;
    border-radius: 6px 6px 0 0;
}


@media (max-width: 768px) {
  .duty-calculator-page-main-container {
    flex-direction: column;
  }
  .duty-calculator-section1{
    width: 100%;
  }
  .duty-calculator-section2{
    width: 100%;
  }
  .point-deduction-msg,
.point-want-to-proceed {
  text-wrap: wrap;
}
}