.contactForm {

    textarea {
        resize: none;
        width: 100%;
        padding: 2%;
        border-radius: 0.05rem;

    }

    >div:nth-last-child(1) {
        margin-block: 1rem;
    }
}