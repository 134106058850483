.simpleButton{
    button{
        background-color: #1994B7;
        padding-block: 0.5rem;
        padding-inline: 4rem;
        color: #ffffff;
        font-size: 1rem;
        &:hover{
            color: #ffffff;
            background-color: #1994B7;
        }
    }
}