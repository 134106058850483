.currentPlanContainer {
    padding: 2%;

    .currentPlan {
        display: flex;
        margin-block-start: 2rem;
        justify-content: space-between;

        .card {
            // background-color: #ffffff;
            width: 32%;
            padding: 2.5%;
            border-radius: 0.5rem;

            .field {
                p {
                    margin-block: 0.6rem;
                }
            }
            .field+hr+div{
                display: flex;
                justify-content: center;
                button {
                    border-radius: 2rem;
                }
            }
        }
    }
}