.forgotPasswordContainer {
    .forgotPassword {
        padding-block: 5%;
        padding-inline: 12%;
        position: relative;
        margin-block-start: 5rem;

        .leftSide {
            background-color: #0088AF;
            padding-inline: 2%;
            padding-block-start: 4%;
            border-radius: 1rem;

            img {
                width: 45%;
                height: 10%;
            }

            >div {
                img {
                    width: 100%;
                    height: 25rem;
                    object-fit: cover;
                    margin-block-end: -1.1rem;
                }
            }

        }

        .rightSide {
            border-radius: 1rem;
        }
    }

    .forgotPassword+div {
        img:nth-child(2) {
            position: absolute;
            top: 7%;
            right: 0%;
            z-index: -1;
        }

        img:nth-child(1) {
            position: absolute;
            bottom: 21%;
            left: 0;
            z-index: -1;
        }
    }

    .forgotPassword+div+div {
        margin-block-start: 6rem;
    }
}

@media screen and (max-width:480px) {
    .forgotPasswordContainer {
        border: 2px solid blue;

        .forgotPassword {
            padding-block: 10%;
            padding-inline: 5%;
            position: relative;
            margin-block-start: 1rem;

            .leftSide {
                background-color: #0088AF;
                padding-inline: 2%;
                padding-block-start: 4%;
                border-radius: 1rem;

                img {
                    width: 45%;
                    height: 10%;
                }

                >div {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }

            .rightSide {
                border-radius: 1rem;
                margin-block-start: 2rem;

                >div {
                    padding: 0%;
                    width: 100%;
                }

            }
        }

        .forgotPassword+div {
            img:nth-child(2) {
                position: absolute;
                top: 4%;
                right: 0%;
                z-index: -1;
                width: 30%;
            }

            img:nth-child(1) {
                position: absolute;
                bottom: 42%;
                left: 0;
                z-index: -1;
                width: 30%;
            }
        }

        .forgotPassword+div+div {
            margin-block-start: 6rem;
        }
    }

}