.profileContainer {
    padding: 2.5%;

    .profile {
        margin-block-start: 2rem;
        display: flex;
        justify-content: space-between;
        gap:5px;

        .leftSide {
            // width: 25%;
            width: fit-content;
            height: fit-content;
            padding-inline: 2%;
            padding-block: 4%;
            border-radius: 0.5rem;

            >div {
                >div:nth-child(1) {
                    width: 10rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
            }
        }

        .rightSide {
            width: 70%;
            height: fit-content;
            padding: 2%;
            border-radius: 0.5rem;

            .field {
                margin-block-start: 1rem;
                display: flex;
                justify-content: space-between;

                >div {
                    width: 48%;
                }
            }

            .field+div {
                margin-block-start: 1rem;
                display: flex;
                justify-content: center;
            }
        }
    }

    /* Media query for mobile screens */
    @media (max-width: 768px) {
        .profile {
            flex-direction: column; 
            align-items: center; 

            .leftSide,
            .rightSide {
                width: 100%;
                margin-bottom: 1.5rem; 
            }

            .rightSide .field {
                flex-direction: column; 
                >div {
                    width: 100%;
                    margin-block-start: 1rem;
                }
            }
        }
    }
}
