.mainconatiner {
    height: 100%;
    background-color: white;
}


.blogImage {
    width: 100%;
    top: 55px;
    right: 0px;
}

.inputSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    padding: 0 20px;
}

.dropdown {
    padding: 8px;
    font-size: 16px;
}

.leftDropdown {
    flex: 1;
}

.centerDropdown {
    flex: 1;
    margin: 0 20px;
}

.searchContainer {
    position: relative;
    flex: 2;
}

.searchBox {
    width: 70%;
    padding: 8px 30px 8px 8px;
    font-size: 16px;
    border: none;
    margin-left: 100px;
    border-bottom: 1px solid #000;

}

.searchIcon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #000;
    /* Color of the search icon */
}