.glossaryContainer {
    >div:nth-child(1) {
        position: fixed;
        z-index: 1;
        margin-block-start: -6%;
    }

    >div:nth-child(2) {
        // padding-block-start: 10%;
        margin-block-start: 6%;
    }

    .glossary {
        padding-inline: 10%;
        padding-block: 5%;

        >div:nth-child(1) {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 1rem;

            span {
                font-size: 1rem;
            }

            ul {
                >div {
                    margin-block: 0.5rem;
                }
            }

            .normal {
                border: 1px solid #0C98BC;
                background-color: #0C98BC;
                color: #ffffff;
            }

            .custom {
                border: 1px solid #0C98BC;
            }
        }

        >div:nth-child(2) {}
    }
}