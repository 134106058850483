.faqaccordionContainer {
    padding-inline: 8%;
    padding-block: 5%;
}

.faqaccordionContainer > div:nth-child(1) h4 {
    color: #000000;
    font-size: 2rem;
}

.faqaccordionContainer > div:nth-child(2) {
    margin-block-start: 2.5%;
}

.faqaccordionContainer > div:nth-child(2) > div {
    background-color: transparent;
    box-shadow: none;
}

.faqaccordionContainer > div:nth-child(2) > div > div {
    padding-inline: 0%;
}

.faqaccordionContainer .MuiAccordionDetails-root {
    padding-inline: 0%;
    background-color: transparent;
}

.faqaccordionContainer .MuiPaper-root.MuiAccordion-root {
    padding-inline: 0%;
    background-color: transparent;
    box-shadow: none;
}

@media screen and (max-width: 480px) {
    .faqaccordionContainer {
        padding-block: 10%;
        padding-inline: 5%;
    }
}
