/* -------------------------------------------login page start-------------------------------------------- */
.login-auth-section {
    display: flex;
    width: 95%;
    /* min-height: 90vh; */
    margin: 10px auto;
    padding: 50px 100px;
    box-shadow: 0px 0px 7px #3c3c3c;
    border-radius: 7px;
}

.login-left-section {
    position: relative;
    display: flex;
    width: 60%;
}

.login-right-section {
    width: 40%;
}

.login-image {
    position: absolute;
    top: 5%;
    left: 180px;
    height: 100%;
}

.login-image>img {
    height: 100%;
    width: 100%;
}

.if-dont-have-acc>a{
    font-size: medium;
}

.seperate-method{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.seperate-method .dark-divider{
    width: 30%;
    height: 2px;
    background-color:var(--intgrow-primary-color);

}

.seperate-method .light-divider{
    width: 30%;
    height: 2px;
    background-color: #fff;
}
.login-with-mobile{
    text-align: center;
}
.login-with-mobile button{
    background-color:var(--intgrow-primary-color);
    border: none;
    padding: 10px;
    color: #fff;
    font-weight: 900;
    width: 100%;
    border-radius: 5px;
}

.sso-section{
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.login-with-sso{
    display: flex;
    gap: 20px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
.sso-dark{
    background-color: #fff;
}

/* .sso-light{
    background-color:var(--main-primary-color);
} */
.login-with-sso>.google-login-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* width: 15%; */
    border: none;
    padding: 5px;
    border-radius: 3px;
    background-color: #fff;
}
.login-with-sso>.google-login-btn>img{
  height: 100%;
}
/* ---------------------------------------------------------login page end--------------------------------- */



/* ---------------------------------------------------------signup page start-------------------------------- */
.signup-auth-section {
    display: flex;
    width: 95%;
    min-height: 90vh;
    margin: 0 auto;
    padding: 50px 100px;
}

.signup-left-section {
    position: relative;
    display: flex;
    width: 50%;
}

.signup-right-section {
    width: 50%;
}

.signup-img>img{
height: 300px;
width: 100%;
}

/* ----------------------------------------------------------signup page end--------------------------------- */

@media (max-width:992px) {
    .login-auth-section {
        padding: 50px 20px;
        width: 98%;
    }
    .login-title>img{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .login-auth-section {
        flex-direction: column;
        padding: 50px 10px;
        width: 99%;
    }
    .login-title>img{
        width: 80%;
    }

    .login-right-section,.login-left-section {
        width: 100%;
    }
}