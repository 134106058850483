.privacyContainer {
    .header {
        display: flex;
        padding-inline: 8%;
        padding-block: 1%;
        border: 2px solid yellow;



        >div:nth-child(2) {
            display: flex;
            justify-content: space-between;

            p {
                margin-block: auto;
                font-size: 1rem;
                font-weight: 600;
            }
        }

    }

    >div:nth-child(1) {
        position: fixed;
        z-index: 1;
        margin-block-start: -6%;
    }

    >div:nth-child(2) {
        // padding-block-start: 10%;
        margin-block-start: 6%;
    }


    .privacy {
        padding-block: 5%;
        padding-inline: 8%;

        // background-color: #ffffff;
        >div {
            display: flex;
            margin-block: 1.25rem;

            svg {
                font-size: 1.6rem;
                color: #0088AF;
            }

            p {
                margin-inline-start: 0.5rem;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .privacyContainer {
        .privacy {
            padding-block: 10%;
            padding-inline: 5%;

        }

    }

}