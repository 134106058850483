.export-price-calculator{
    background-color: #0088af;
    color: #fff !important;
    padding: 10px;
    border-radius: 7px;
}

.offile-calculator{
    padding: 20px;
    margin: 20px;
}
.result{
    background-color: #ff6500;
    padding: 10px;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
}

.offile-calculator {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.export-price-calculator {
    text-align: center;
    margin-bottom: 20px;
}

.result {
    background-color: #e0e0e0;
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}

@media (max-width: 768px) {
    .offile-calculator{
        padding:2px;
    }
  }