.main-container {
    margin: 20px auto;
  }
  .main-container>img{
    width:100%;
    height: 200px;
  }
  
  .paragraph {
    margin-top: 20px; /* Adjust the value as needed to control the space */
   margin-left: 30px;
   margin-right: 30px;
 
   font-size: medium;
  
  }
  
  .banner {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    /* You can adjust the following properties as needed */
    /* width: 100%; */
    /* height: 300px; */
    /* object-fit: cover; */
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    max-width: 100%;
    background-color: #0088AF;
    margin-top: 100px;
    padding: 20px; /* Optional: Add padding for spacing */
    box-sizing: border-box; /* Include padding in height calculation */
  }
  
  .footerheadingcontainer {
    text-align: center;
  }
  
  .headingfooter {
    color: white;
    margin: 0; /* Remove default margin */
  }
  
  .footerparagraph {
    color: white;
    margin: 10px 0; /* Adjust spacing between elements */
  }
  
  .footerbtn {
    margin-top: 10px; /* Space above button */
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #FF6400;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  

  .subscribe-input-field{
    padding: 10px;
    border: none;
    border-radius: 7px;
    width: 100%;

  }
  .disclaimer-desc-info{
    padding: 60px 0;
    text-align: justify;
  }