.commonBanner {
    padding-block: 5%;
    padding-inline: 8%;
    background-color: #0C98BC;
    color: #ffffff;
}

@media screen and (max-width:480px) {
    .commonBanner {
        padding-block: 15%;
    }

}