.view-section-container {
    padding: 20px;
    position: relative;
  }
  
  /* h1 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: 300px;
  } */
  
  .pagination-info {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
  }
  
  .pagination-info span {
    font-size: 14px;
    margin-right: 10px;
  }
  
  .pagination-buttons {
    display: flex;
    align-items: center;
  }
  
  .pagination-buttons button {
    background-color: #0088AF;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
  }
  
  .bloglist-container {
    padding: 20px;
    margin-left: 20%;
    width: 75%;
  }
  
  .bloglist-table-wrapper {
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
  }
  
  .bloglist-table {
    width: 100%; /* Set the table width to 100% of the container */
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  /* thead {
    background-color: #f9f9f9;
  }
  
  th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    font-weight: bold;
  } */
  
  .update-button {
    background-color:  #0088AF;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-button {
    background-color: #0088AF;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #FF4500;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .rows-per-page {
    display: flex;
    align-items: center;
  }
  
  .rows-per-page span {
    font-size: 14px;
  }
  

  @media (max-width: 768px) {
    .support-container{
      padding: 2px;
    }
    .view-section-container {
      padding: 2px;
    }
  }