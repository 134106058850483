.SearchBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div {
        width: 70%;

        >div {
            border-radius: 2rem;
            background-color: #ffffff;
            border: unset;
        }
    }

    button {
        // padding-inline: 2.75rem;
        // border-radius: 2rem;
        // font-weight: 600;
        // background: linear-gradient(101.61deg, #84EEF5 -14.72%, #FD8406 66.97%);
        // border: unset;

        padding: 15px 30px;
        border: 1px solid #ffffff;
        border-radius: 2rem;
        margin: 1rem 0 1rem 0;
        color: #fff;

        &:hover {
            // border: unset;
        }
    }


}

@media screen and (max-width:480px) {
    .SearchBox {
        width: 100%;

        >div:nth-child(1) {
            width: 60%;
        }

        >div:nth-child(2) {
            width: 40%;
        }
    }

}