.cookieModalContainer {
    width: 50%;
    height: fit-content;
    margin: auto;

    .cookieModal {
        background-color: #ffffff;
        padding: 5%;
        border-radius: 0.5rem;

        .action {
            display: flex;
            justify-content: right;
            margin-block-start: 1rem;

            button {
                border-radius: 2rem;
                padding-inline: 2rem;
                border: 1px solid #0C98BC;

                &:hover {
                    background-color: #0C98BC;
                    color: #ffffff;
                }
            }

            >button:nth-child(1) {
                margin-inline-end: 0.5rem;
            }

        }
    }
}