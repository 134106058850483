.commonButton {
    display: flex;
    justify-content: center;

    >div {
        display: flex;
        color: #000;
        background-color: #fff;
        width: 100%;
        padding-inline: 2rem;
        padding-block: 0.25rem;
        border: 1px solid #ffffff;
        border-radius: 2rem;

        &:hover {
            // color: #444444;
            cursor: pointer;

            button {
                // color: #444444;
                background-color: #fff;

            }
        }

        svg {
            margin-block: auto;
        }

        button {
            // color: #ffffff;
            width: 100%;
            background-color: transparent;
        }
    }



}