.faqContainer {
    padding-inline: 10%;
    padding-block: 5%;
}

@media screen and (max-width:480px) {
    .faqContainer {
        padding-inline: 5%;
        margin-block-start: 1rem;
    }

}