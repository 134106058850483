.signUpContainer {
    >div:nth-child(1) {
        position: fixed;
        z-index: 1;
    }

    .signUp {
        padding-inline: 12%;
        padding-block-start: 10%;
        position: relative;

        .leftSide {
            background-color: #0088AF;
            padding-inline: 2%;
            padding-block-start: 4%;
            border-radius: 1rem;
            height: 35.5rem;

            img {
                width: 45%;
                height: 10%;
            }

            >div {
                // position: absolute;
                left: 10%;

                img {
                    width: 100%;
                    height: 25rem;
                    object-fit: cover;
                }
            }

        }

        .rightSide {
            border-radius: 1rem;
            padding-inline-start: 5%;
        }
    }

    .signUp+div {
        img:nth-child(2) {
            position: absolute;
            top: 7%;
            right: 0%;
            z-index: -1;
            width: 145px;
        }

        img:nth-child(1) {
            position: absolute;
            bottom: 21%;
            left: 0;
            z-index: -1;
        }
    }

    .signUp+div+div {
        margin-block-start: 6rem;
    }
}

@media screen and (max-width:480px) {
    .signUpContainer {

        .signUp {
            padding-block: 10%;
            padding-inline: 5%;
            position: relative;

            .leftSide {
                background-color: #0088AF;
                padding-inline: 2%;
                padding-block-start: 4%;
                border-radius: 1rem;
                height: 21rem;
                width: 100%;

                img {
                    width: 45%;
                    height: 10%;
                }

                >div {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }

            .rightSide {
                border-radius: 1rem;
                margin-block-start: 2rem;

                >div {
                    padding: 0%;
                    width: 100%;
                }

            }
        }

        .signUp+div {
            img:nth-child(2) {
                position: absolute;
                top: 4%;
                right: 0%;
                z-index: -1;
                width: 30%;
            }

            img:nth-child(1) {
                position: absolute;
                bottom: 42%;
                left: 0;
                z-index: -1;
                width: 30%;
            }
        }

        .signUp+div+div {
            margin-block-start: 6rem;
        }
    }

}