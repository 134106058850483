.form-wrapper {
    position: relative;
    width: 50%;
    max-width: 900px;
  
    margin-left: 400px;
  }
  
  .form-heading {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .form-container {
    background-color: #fff;
    border: 0.5px solid gray; /* Black border */
    padding: 20px;
    border-radius: 8px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .input-container {
    position: relative;
    flex: 1;
  }
  
  .input-container input,
  .input-container textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-container .floating-label {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #fff;
    padding: 0 4px;
    color: #000;
    transition: 0.2s;
    pointer-events: none;
  }
  
  .input-container input:focus + .floating-label,
  .input-container textarea:focus + .floating-label,
  .input-container input:not(:placeholder-shown) + .floating-label,
  .input-container textarea:not(:placeholder-shown) + .floating-label {
    top: -12px;
    left: 4px;
    font-size: 12px;
    color: #000;
  }
  
  .description-container textarea {
    height: 80px; /* Adjust height as needed */
  }
  
  .submit-button {
    display: block;
    width: 200px;
    padding: 10px;
    margin: 20px auto 0;
    background-color: #007bff; /* Blue color */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  