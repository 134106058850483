.lightLabel,.darkLabel{
    padding: 5px;
    border-radius: 50%;
}

.darkLabel{
    background-color: #444444;
}
.lightLabel{
    background-color: #000;
}

/* ----------------------------------------------------divider----------------------------------------------------------------- */
.hs-divider{
  width: 100%;
  height: 1px;
  border: 1px dotted #444444;
  margin-bottom: 5px;
}
/* ---------------------------------------------- */
.searchHsCodeinput{
    width: 100%;
    padding:12px 5px;
    border:1px solid #ccc;
    border-radius: 4px;
    margin-top: 16px;
  }
.button {
    width: 25%;
    min-width: 150px;
    display: inline-block;
    padding: 15px 0;
    border: none;
    background-color:var( --intgrow-primary-color);
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border-radius: 12px;
    transition-duration: 0.8s;
  }
  
  .button:after {
    content: "";
    background: #90EE90;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 1s
  }
  
  .button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }


  /* ------------------------------------------------hs code selector-------------------------------------- */
 

  


  

  /* ---------------------------------------------------hs result table ---------------------------------- */
  .hs-result-table{
    margin-top: 20px;
  }

  .errorMsg{
    color: red;
  }








  /* ----------------------------------------------testing------------------------------------- */
  