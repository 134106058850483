.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enables vertical scrolling */
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 100%;
  height: auto;
}

.section {
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  background-color: #0088AF;
  color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.section:hover {
  background-color: #0056b3;
}

.blog-menu {
  margin-top: 10px;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  border-left: 3px solid transparent;
  background-color: #e9ecef;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.menu-item:hover {
  background-color: #ddd;
  border-left: 3px solid #c2b6bc;
}

.active {
  background-color: #c2b6bc;
  color: white;
}

.submenu {
  padding-left: 20px;
}

.submenu-item {
  padding: 10px;
  cursor: pointer;
  background-color: #f4f4f4;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.submenu-item:hover {
  background-color: #D9EDF3;
}

.submenu-item.active {
  background-color: #c2b6bc;
  color: white;
}
