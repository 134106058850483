.heroCardContainer {
    border: 1px solid #D9DEF1;
    width: 100%;
    border-radius: 0.8rem;
    background-color: #fff;
}
.subHeroCardContainer {
    padding-block: 1%;
}

.subHeroCardContainer .subHeroCard {
    border-right: 1px dashed #D9DEF1;
    width: 20%;
    padding-inline:5px;
}

.subHeroCardContainer .subHeroCard .heading {
    font-size: 1rem;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.subHeroCardContainer .subHeroCard .number {
    font-size: 1.65rem;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0px;
    text-align: left;
    color: #FF6600;
}

.subHeroCardContainer .subHeroCard .icon {
    width: 2.5rem;
    height: 2.5rem;
    top: 145.48px;
    left: 478px;
    margin-block-start: -0.25rem;
    color: #ffffff;
    background-color:#0088af;
    padding: 0.40rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subHeroCardContainer .subHeroCard .label {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: right;
    margin-block-start: -0.5rem;
}
