.profileSettingContainer {
    padding: 2%;

    .profileSetting {
        display: flex;
        justify-content: space-between;
        margin-block-start: 2rem;

        .rightSide {
            width: 48%;
            padding-inline: 2.75%;
            padding-block: 4%;
            border-radius: 0.5rem;

            >div:nth-last-child(1) {
                display: flex;
                justify-content: center;
                margin-block-start: 1rem;
            }
        }
    }

    @media (max-width: 768px) {
        .profileSetting {
            flex-direction: column;
            .rightSide {
                width: 100%; 
                margin-bottom: 1rem;
            }
        }
    }
}
