.mainContainer {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  align-items: center;
  padding: 20px;
}

.blogContainer,
.dark-blogContainer {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.blogContainer:hover,
.dark-blogContainer:hover {
  transform: translateY(-5px);
}

.blogContainer {
  background-color: #fff;
}

.dark-blogContainer {
  background-color: #273142;
}

.contentRow {
  display: flex;
  align-items: flex-start;
}

.blogimage {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover;
}

.textContent {
  flex: 1;
}

.blogTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.blogDescription {
  font-size: 16px;
  margin-bottom: 20px;
}

.blogFooter {
  display: flex;
  gap: 30px;
  align-items: center;
}

.newsType,
.date {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #888;
}

.newsType::before,
.date::before {
  content: '';
  display: inline-block;
  margin-right: 8px;
}



.readMore {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 140px;
  transition: background-color 0.3s ease;
}

.readMore:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.userDetails {
  display: flex;
  gap: 25px;
  margin-bottom: 10px;
}

.userName,
.blogType {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.expandedSection {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.blogContent {
  font-size: 16px;
  color: #444;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paginationButton,
.nextButton {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
}

.paginationButton.active,
.paginationButton:hover,
.nextButton:hover {
  background-color: #0056b3;
}
