/* --------------------------------------------------------------login form------------------------------------ */
.login-form-section{
    width: 60%;
    margin: 2px auto;
}
.normal-login-btn{
    text-align: center;
    border-radius: 7px;
    width: 100%;
    border: none;
    padding: 10px;
    background-color: #0088af;
}
.already-have-account{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
}
.already-have-account>a{
    padding: 2px 10px;
    border-radius: 7px;
    background-color:var(--intgrow-primary-color);
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}
.social-login-section{
    display: flex;
    display: flex;
    justify-content:center;
    padding:5px 0;
    gap: 10px;
}

.google-login-btn,.linkedIn-login-btn,.Mobile-login-btn{
    width: 16%;
    border: none;
    border-radius: 5px;
    background-color: transparent;
}

.google-login-btn:hover,.linkedIn-login-btn:hover,.Mobile-login-btn:hover{
    background-color:#727272;
}
.google-login-btn>img,.linkedIn-login-btn>img,.Mobile-login-btn>img{
    width: 100%;
}

.recaptcha-error{
    margin: 0px !important;
}

/* ------------------------------------------------------------signup------------------------------------------ */
.continue-with-social-login{
    display: flex;
    background-color: black;
}
/* -------------------------------------------------------------signup end-------------------------------------- */
/* --------------------------------------------------------------login form end-------------------------------- */
/* -----------------------------------------------------forgot password start----------------------------------- */
.main-form-section {
   
}

.top-strip {
    height: 100px;
    background-color: var(--main-primary-color);
}

.form-section {
    border-radius: 28px;
}

.importantInformation{
    text-align: justify;
    color: #727272;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -1.2px;
    }
    .readInformation{
        text-align: justify;
        color: #727272;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -1.2px;
    }
    .pleaseRemember{
        text-align: justify;
        color: #727272;
        font-size: 14px;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: -1.2px;
    }
    .forgotPasswordSection{
        width: 100%;
        /* box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);  */
    }
    
    .forgotInputSection{
      width: 100%;
    }
    
    .dontWorry{
        color: #fff;
    }
    .placeLogo{
        position: absolute;
        top: 2%;
        left: 2%;
    }

/* --------------------------------------------forgot password end------------------------------------------- */

/* --------------------------------------------reset password start------------------------------------------------- */
.resetPasswordInputSection{
    width: 100%;
    /* background-color: #19508a; */
    background-color: #0088AF;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px 0 0 30px;
}

/* ---------------------------------------------reset password end--------------------------------------------- */


/* ---------------------------------------------Verify OTP and Recaptcha start----------------------------------------- */
.Otp-verification-section{
padding: 10px;
width: 90%;
}
.otp-main-container{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border: 1px solid;
    border-radius: 12px;
    box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.8);
}
.input-Otp-title{
 color:var(--intgrow-primary-color);
}
.otp-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.otp-input{
    display: flex;
    justify-content: space-between;
}
.resend-otp{
    cursor: pointer;
    color:var(--intgrow-primary-color);
}
/* ---------------------------------------------Verify OTP and Recaptcha end----------------------------------------- */


@media (max-width:900px) {
    .form-section{
        top:160%
    }
    .forgotPasswordSection{
        width: 90%;
    }
}

@media (max-width: 768px) {
    .form-section{
        top:160%
    }
    .forgot-password-form{
        width: 100%;
    }
}
@media (max-width: 428px) {
    .form-section{
        width: 95%;
        margin: 0 auto;
    }
}