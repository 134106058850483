.subscribeContainer {
    padding-block: 5%;
    padding-inline: 8%;
    background-color: #0C98BC;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    position: relative;

    >div:nth-child(2) {
        margin-block: auto;
    }

    p {
        position: absolute;
        bottom: 2.75%;
    }
}

@media screen and (max-width:480px) {
    .subscribeContainer {
        padding-block-start: 10%;
        padding-block-end: 20%;
        padding-inline: 5%;

        >div:nth-child(1) {

            h2 {
                font-size: 2rem;
                margin-block-end: 1rem;
            }
        }

        >div:nth-child(2) {
            width: 100%;

            >div {
                display: flex;
                flex-direction: column;

                >div {
                    width: 100%;
                    margin-block: 1rem;
                }

                button {
                    padding-block: 0.85rem;
                }
            }
        }
    }

}