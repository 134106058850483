.support-container {
    padding-inline: 1%;
}

.support-container>div {
    padding-block: 2%;
}

.support-banner {
    background-color: #1994B7;
    padding-block: 1.8rem;
    border-radius: 0.5rem;

}

.support-form {
    width: 99%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    border-radius: 0.5rem;
}
.selectField {
    >div {
        width: 100%;
    }

    div[class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"] {

        &:hover,
        &:focus-visible {
            border: none !important;
        }
    }
}
.support-image {
    width: 40%;
    height: fit-content;
    margin: auto;
    /* background-color: #1994B7; */
    border-radius: 0.5rem;
    display: flex !important;
    flex-direction: column !important;
    padding: 4%;

}

.support-image>img {
    object-fit: cover;
}

.support-image>img:nth-child(2) {
    margin-block-end: 2.5rem;
}
.support-image>img:nth-child(3) {
    width: 80%;
    margin: auto;
}

.support-field {
    width: 60%;
    padding: 4%;
}
.support-field>div{
    margin-block: 1rem;
}
.support-field>div:nth-last-child(1){
    display: flex;
    justify-content: center;
    margin-block-start: 1.5rem;
    button{
        padding-inline: 6rem;
    }
}
.style-message{
    resize: unset;
    width: 100%;
    border-radius: 0.25rem;
    padding: 0.25rem;
}

@media (max-width: 768px) {
    .support-form {
      flex-direction: column;
    }
    .support-image,.support-field{
        width: 100%;
    }
  }