.slick-list {
width: 100%;
}
.slide{
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.slide>img{
    width: 100%;
    height: 100%;
    border-radius: 7px;
}