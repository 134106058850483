.hsCodeTreeContainer .modifyBtn {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
}

.hsCodeTreeContainer .modifyButton {
    background-color: #1994B7;
    padding-block: 0.5rem;
    width: 100%;
    color: #ffffff;
    font-size: 1rem;
    padding: 12px;
}

.hsCodeTreeContainer .modifyButton:hover {
    color: #ffffff;
    background-color: #1994B7;
}

.hsCodeTreeContainer .hsCodeTreeHeader {
    display: flex;
    justify-content: space-between;
    margin-block-start: 2rem;
}

.hsCodeTreeContainer .hsCodeTreeHeader > div:nth-child(1) {
    width: 35%;
    padding-block-end: 0%;
}

.hsCodeTreeContainer .hsCodeTreeHeader > div:nth-child(1) > div {
    margin-block-start: 0.5rem;
}

.hsCodeTreeContainer .hsCodeTreeHeader > div:nth-child(2),
.hsCodeTreeContainer .hsCodeTreeHeader > div:nth-child(3) {
    width: 23.5%;
}

.hsCodeTreeContainer .hsCodeTreeHeader > div:nth-child(4) {
    margin-block-start: auto;
}

.hsCodeTreeContainer .hsCodeTreeHeader > div:nth-child(4) button {
    padding-block: 0.7rem;
}

.hsCodeTreeContainer .hsCodeTree {
    // display: flex;
    justify-content: space-between;
    margin-block: 1rem;
}

.hsCodeTreeContainer .hsCodeTree .leftSide {
    // width: 30%;
    border: 0.3px solid #B9B9B9;
    border-radius: 0.5rem;
}

.hsCodeTreeContainer .hsCodeTree .leftSide > div {
    padding: 0.75rem;
    border-radius: 0.25rem;
    text-align: center;
    cursor: pointer;
}

.hsCodeTreeContainer .hsCodeTree .leftSide > div:nth-child(2) {
    position: relative;
}

.hsCodeTreeContainer .hsCodeTree .leftSide > div:nth-child(2) .expandTree {
    position: absolute;
    right: 5%;
    bottom: 20%;
}

.hsCodeTreeContainer .hsCodeTree .rightSide {
    // width: 68%;
    border-radius: 7px;
}

.hsCodeTreeContainer .hsCodeTree .rightSide > div {
    border-radius: 0.25rem;
}

.hsCodeTreeContainer .hsCodeTree .proceedToDutyCal {
    align-items: center;
    display: flex;
    justify-content: center;
}

.hsCodeTreeContainer .hsCodeTree .proceedToDutyCal button {
    width: 70%;
    background-color: #1994B7;
    color: #fff;
    font-weight: 700;
    padding: 10px;
    font-size: 16px;
}
.tree-node {
    position: relative;
    padding-left: 20px;
}

.tree-node::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 2px;
    background-color: transparent; /* Initially transparent */
}

.tree-node-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    position: relative;
}

.tree-node-label::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    width: 20px;
    height: 2px;
    background-color: transparent; /* Initially transparent */
    transform: translateY(-50%);
}

.tree-node-label.expanded::before,
.tree-node.expanded > .tree-node-children::before {
    background-color: #000; /* Set to black when expanded */
}

.tree-node-children {
    margin-left: 15px;
    position: relative;
}

.tree-node-children::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    width: 2px;
    height: 30p;
    background-color: #000; /* Vertical line */
}


@media (max-width: 768px) {
    .hsCodeTreeContainer .hsCodeTree {
        flex-direction: column;
        gap: 10px;
    }
    .hsCodeTreeContainer .hsCodeTree .rightSide {
        width: 100%;
    }
    .hsCodeTreeContainer .hsCodeTree .leftSide {
        width: 100%;
   
    }
}