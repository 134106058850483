.cookieContainer {

    >div:nth-child(1) {
        position: fixed;
        margin-block-start: -6%;
    }

    >div:nth-child(2) {
        margin-block-start: 6%;
    }

    .cookie {
        padding-inline: 10%;
        padding-block: 5%;
        p{
            margin-block: 1rem;
        }
    }
}