.main-page-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    margin-top: 5px;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.center-section {
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    display: flex;
    width: 80%;
    z-index: 1;
    box-shadow: 0px 0px 7px #3c3c3c;
    border-radius: 7px;
}

.welcome-back{
    color: var(--intgrow-secondary-color);
}
.login-left,.login-right,.login-right-dark{
    width: 50%;
}
.login-left{
    background-color: var(--intgrow-primary-color);
    border-radius: 7px 0 0 7px;
}

.login-right{
    background-color: #fff;
    border-radius: 0 7px 7px 0;
}
.login-right-dark{
    background-color:#040509;
    border-radius: 0 7px 7px 0;
}
.img-section{
    z-index: 1;
    position: relative;
    height: 380px;
}
.description-section{
    padding: 0 20px;
}

.description-section>img{
    width: 33%;
}

.img-section>img{
    width: 112%;
}

.seperate-method{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.seperate-method .dark-divider{
    width: 30%;
    height: 2px;
    background-color:var(--intgrow-primary-color);

}

.seperate-method .light-divider{
    width: 30%;
    height: 2px;
    background-color: #fff;
}