.helpContainer {
    padding: 2%;

    >div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        // padding-inline-end: 2.5rem;
        position: relative;

        >div {
            display: flex;
            justify-content: space-between; 
            background-color: #0088AF;
            color: #ffffff;
            height: fit-content;
            margin-block: auto;
            padding-inline: 3rem;
            padding-block: 1rem;
            border-radius: 0.5rem;
            cursor: pointer;
            min-width: 220px;
        }
        ul{
            position: absolute;
            top: 102%;
            right: 3.3%;
            // background-color: #0088AF;
            border-radius: 0.5rem;
            // color: #ffffff;
            li{
                font-size: 0.8rem;
            }
        }
    }

    .help {
        margin-block-start: 2rem;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;

        .card {
            // width: 23%;
            height: fit-content;
            margin: 1%;
            margin-block: 0.5rem;
            // background-color: #ffffff;
            border-radius: 0.5rem;

            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
                border-radius: 0.5rem 0.5rem 0 0;
            }

            h5 {
                text-align: center;
                padding-block: 1rem;
            }
        }
    }

    .paggination {
        display: flex;
        justify-content: right;
        margin-block-start: 1rem;
        >div:nth-child(1){
            margin-inline-end: 0.5rem;
        }
        >div{
            background-color: #0088AF;
            color: #ffffff;
            padding-block: 0.8rem;
            padding-inline: 2rem;
            height: fit-content;
            border-radius: 0.5rem;
            cursor: pointer;
            svg{
                margin-block: "auto";
            }
            h5{
                margin-block: "auto";
            }
        }

    }
}