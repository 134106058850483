@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');



:root {
  /* --main-background-color: #141b2d; */
  --main-background-color: #000000;
  --main-title-text-color: #54595f;
  --secondary-dark-background: #2fccd6;
  --secondary-light-background: #2fccd6;

  --icon-primary-bg: #0c8068;
  --icon-secondary-bg: #ffcb69;
  --icon-tertiary-bg: #2fccd6;

  --font-family: "Source Sans 3", sans-serif;
  /* --font-family: "Open Sans", sans-serif; */
  --main-primary-color: #0e588d;
  --main-secondary-color: #ff6600;

  --intgrow-primary-color: #0088AF;
  --intgrow-secondary-color: #FFBD59;
  /* --intgrow-tertiary-color: #4FB9F6; */
  --intgrow-tertiary-color: #FFFFFF;


  /* background-color: #0088af; */


  --intgrow-bg: #0D7CBD;


  --intgrow-small-font: 12px;
  --intgrow-medium-font: 14px;
  --intgrow-large-font: 16px;
  --intgrow-xlarge-font: 18px;
  --intgrow-xxlarge-font: 20px;


}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  /* font-family: "Source Sans 3", sans-serif; */
  /* --font-family: "Open Sans", sans-serif; */
  font-family: "Open Sans", sans-serif;

}

.content {
  height: 100vh;
  overflow-y: auto;
}

.btn.btn-primary {
  background-color: var(--intgrow-primary-color);
  padding: 10px 0px;
  border: none;
  border-radius: 7px;
}


.btn.btn-secondary {
  background-color: var(--intgrow-secondary-color);
  padding: 10px 0px;
  border: none;
  border-radius: 7px;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* handler */
::-webkit-scrollbar-thumb {
  background: #888;
}


/* handle on hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

/* capitalize
   icon center
 */