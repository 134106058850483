.headerContainer {
    padding-inline-start: 10%;
    padding-inline-end: 18%;
    padding-block: 1.15%;
    height: 90px;
    transition: 0.3s ease-in;
  
    > div:nth-child(2) {
      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
  
        h5 {
          padding-inline: 2rem;
        }
      }
  
      > div:nth-child(2) {
        display: none;
      }
    }
  }
  
  .header_bg {
    background-color: #ffffff;
  
    h5 {
      color: #0c98bc;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .headerContainer {
      padding-inline: 5%;
      padding-block: 5%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
  
      > div:nth-child(2) {
        display: flex;
        justify-content: right;
  
        > div:nth-child(1) {
          display: none;
        }
  
        > div:nth-child(2) {
          display: block;
        }
  
        .menu {
          position: absolute;
          top: 100%;
          right: 0%;
          width: 100%;
          background-color: #ffffff;
          color: #000000;
        }
      }
    }
  }