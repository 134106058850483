.termsAndCondContainer {
    width: 80%;
    height: fit-content;
    margin: auto;

    .termsAndCond {
        background-color: #ffffff;
        padding: 5%;
        border-radius: 0.5rem;

        svg {
            color: #444444;
        }

        h5 {
            color: #444444;
        }

        >div:nth-child(1)+hr+div {
            height: 20rem;
            overflow-y: scroll;


            p {
                margin-block: 1rem;
                color: #444444;
            }
        }

        >div:nth-last-child(1) {
            display: flex;
            justify-content: space-between;
            margin-block-start: 1rem;

            button {
                border-radius: 2rem;
                border: 1px solid #0088AF;
                padding-inline: 2rem;

                &:hover {
                    background-color: #0088AF;
                    color: #ffffff;
                }
            }
        }

    }
}