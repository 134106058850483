/* styles.css */
.cookie-consent-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-consent-popup button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}