.selectHScodeContainer {
    margin-block-start: 2rem;
  }
  
  .hsCodeTreeHeader {
    display: flex;
    justify-content: space-between;
    margin-block-start: 2rem;
  }
  
  .hsCodeTreeHeader > div:nth-child(1) {
    width: 35%;
  }
  
  .hsCodeTreeHeader > div:nth-child(1) > div {
    margin-block-start: 0.5rem;
  }
  
  .hsCodeTreeHeader > div:nth-child(2),
  .hsCodeTreeHeader > div:nth-child(3) {
    width: 23.5%;
  }
  
  .hsCodeTreeHeader > div:nth-child(4) {
    margin-block-start: auto;
  }
  
  .hsCodeTreeHeader > div:nth-child(4) button {
    padding-block: 0.7rem;
  }
  
  .hsCodeTree {
    display: flex;
    justify-content: space-between;
    margin-block: 1rem;
  }
  
  .leftSide {
    width: 30%;
    height: fit-content;
    background-color: #ffffff;
    border: 0.3px solid #B9B9B9;
    padding-inline: 2%;
    padding-block: 1%;
    border-radius: 0.5rem;
  }
  
  .leftSide > div {
    margin-block: 0.85rem;
    padding: 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .arrowimg {
    position: relative;
  }
  
  .arrowimg img {
    position: absolute;
    right: 5%;
    bottom: 20%;
  }
  
  .rightSide {
    width: 100%;
  }
  
  .rightSide .desc {
    padding: 2%;
    border-radius: 0.25rem;
  }
  
  .rightSide .desc p {
    cursor: pointer;
  }
  
  .findCode {
    display: flex;
    gap: 5px;
    overflow-x: auto;
  }
  
  .import,
  .export {
    border-radius: 0.5rem;
    min-width: 500px;
    width: 50%;
    border: 1px solid #d8d8d8;
    height: 450px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .import::-webkit-scrollbar,
  .export::-webkit-scrollbar {
    display: none;
  }
  
  .import .importingCountryName,
  .export .exportingCountryName {
    background-color: #1994b7;
    color: #fff;
  }
  
  .card {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
  }
  
  .card > div:nth-child(1),
  .card > div:nth-child(3) {
    margin: auto;
  }
  
  .card > div:nth-child(2) {
    width: 65%;
    border-left: 0.3px solid #B9B9B9;
    border-right: 0.3px solid #B9B9B9;
    padding-block: 2%;
    padding-inline: 1%;
  }
  
  .card > div:nth-child(3) {
    width: 15%;
    background-color: #E0E0E0;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
    border-radius: 2rem;
    padding-block: 0.25rem;
    cursor: pointer;
    border: none;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: space-around;
  }
  
  .card > div:nth-child(3) p {
    font-weight: 600;
    color: #606060;
  }
  
  .card > div:nth-child(3) > div {
    border: 2px solid #ffffff;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
  }
  
  .card .active {
    border: 2px solid var(--intgrow-primary-color);
    background-color: var(--intgrow-primary-color) !important;
    color: #fff !important;
  }
  
  .card .active p {
    color: #fff !important;
  }
  
  .proceedToDutyCal {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  
  .disableProceedToDutyCal {
    display: none;
  }
  
  .proceedToDutyCal button {
    width: 100%;
    background-color: #1994B7;
    color: #fff;
    font-weight: 700;
    padding: 10px;
    font-size: 16px;
  }
  .proceedToDutyCal button:hover {
    background-color: #1994B7;
  }
  
  @media (max-width: 768px) {
    .findCode {
      flex-direction: column;
      gap: 1rem;
      overflow-x: auto;
    }
  
    .import,
    .export {
        min-width: 768px;
      width: 100%;

    }
  }
  