.footerContainer {
    display: flex;
    justify-content: space-between;
    padding-inline: 8%;
    // color: #ffffff;
    padding-block: 2.5rem;

    >div:nth-child(2) {
        display: flex;
        justify-content: space-between;

        >div {
            width: 24%;
        }

        p {
            margin-block: 1rem;
            font-size: 0.85rem;
            line-height: 1.6rem;
            cursor: pointer;
        }
    }
}

@media screen and (max-width:480px) {
    .footerContainer {
        padding-inline: 5%;
        padding-block: 10%;

        >div:nth-child(2) {
            flex-direction: column;
            width: 100%;

            >div:nth-child(1) {
                margin-block-start: 2rem;
            }

            >div {
                width: 100%;
                border-bottom: 1px solid #e3e3e3;
                margin-block: 1rem;

                p {
                    width: fit-content;
                }
            }

            >div:nth-last-child(1) {
                border-bottom: unset;
                margin-block: unset;
            }
        }
    }

}