/* Optional: Custom styles for the select component */
.select-country-container {
    margin: 0 auto;
    color: #444;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
}

.custom-option {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    /* Ensure the default background color is white */
    color: #333;
    /* Ensure the default text color is #333 */
}

.custom-option.focused {
    background-color: #e6f3ff;
}

.custom-option.selected {
    background-color: #cce6ff;
}

.custom-single-value {
    display: flex;
    align-items: center;
}

.css-13cymwt-control {
    color: #000;
}


.selected-country {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    /* Ensure the background color is white */
    color: #000;
}

/* .css-1fdsijx-ValueContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
} */










