.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
}

.line{
    flex: 1;
    border-bottom: 1px dashed #000;
}
.text{
    white-space:nowrap;
    padding: 0 10px;
}