.appContainer {
    position: relative;

    .theme {
        position: fixed;
        top: 15%;
        right: 2%;

        >div {
            border-radius: 50%;

            svg {
                margin: auto;
            }
        }
    }
}